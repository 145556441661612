<template>
    <section data-v-31c867e7="" class="our_services" id="our_services">
            <div class="container">
                <div class="card mb-5 card-services-item">
                    <div class="card-body">
                        <h2>Support</h2>
                        <p>
                            Electronics engineering is the engineering domain that deals with design and development of
                            electricity powered applications.
                            An electronics engineer makes use of microcontrollers, electronics components with printed
                            circuit boards to develop electronics systems and devices.
                        </p>
                        <router-link to="/support-rn-invent" class="btn">
                            RN Invent
                        </router-link>
                        <router-link to="/support-rn-ai" class="btn">
                            RN AI
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
</template>

<style lang="css" scoped>
 .our_services{
            padding-top: 6rem;
        }
        .card-services-item {
            background: linear-gradient(90deg, #47AED1 8.5%, #DA9664 100%);
            border-radius: 20px;
            border: none;

        }

        .card-services-item .card-body {
            text-align: center;
        }

        .card-services-item h2 {
            font-family: "Cairo";
            font-size: 40px;
            font-weight: 700;
            line-height: 44px;
            text-align: center;
            margin-bottom: 1rem;
            color: rgba(255, 255, 255, 1);
        }

        .card-services-item p {
            font-family: "Cairo";
            font-size: 16px;
            font-weight: 400;
            line-height: 17.6px;
            text-align: center;
            margin-bottom: 1rem;
            padding: 1rem 15rem;
            color: rgba(255, 255, 255, 1);
        }

        .card-services-item a {
            border-radius: 104px;
            background: #fff;
            font-family: "Cairo";
            font-size: 20px;
            font-weight: 600;
            line-height: 33.3px;
            text-align: center;
            margin-bottom: 1rem;
            color: rgba(11, 64, 103, 1);
            padding: .5rem 2rem;
            margin: 0.5rem;
            width: 17%;
        }

        .card-services-item a:hover{
            background: rgba(11, 64, 103, 1) !important;
            color:#fff !important;
        }

        .card-services-item a:hover {
            border: none;
            color: rgba(11, 64, 103, 1);
            background: #fff;
        }
</style>

<script>
export default {
    name: "SupportSection",
};
</script>

