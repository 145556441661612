<template>
  <div class="home">
     <SupportSection/>

        <section data-v-31c867e7="" class="our_" id="our_services">
            <div class="container">
                <div class="mt-5 mb-5">
                    <h2>About RN AI</h2>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the
                        industry's standard dummy
                    </p>
                    <div class="card card-service-info mt-5 mb-5">
                        <div class="card-body">
                            <img src="../assets//rn_images/2ce06e0cdaa244ec7875b5aadcfeb79c.png" />
                            <h3>Lorem Ipsum is simply dummy text of the printing</h3>
                            <router-link to="/projects/2">
                                Our Projects
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <UpperFooterComponent></UpperFooterComponent>
  </div>
</template>

<script>
import SupportSection from "@/components/rn/Support.component.vue"
import UpperFooterComponent from "@/components/layouts/UpprtFooter.component.vue";


export default {
  name: "SupportRNAI",
  components: {
    SupportSection,
    UpperFooterComponent
  },
  created() {
    this.$store.commit("setPage", "SupportRNAI");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
};
</script>
<style lang="css" scoped>
        .our_services{
            padding-top: 6rem;
        }
        .card-services-item {
            background: linear-gradient(90deg, #47AED1 8.5%, #DA9664 100%);
            border-radius: 20px;
            border: none;

        }

        .card-services-item .card-body {
            text-align: center;
        }

        .card-services-item h2 {
            font-family: "Cairo";
            font-size: 40px;
            font-weight: 700;
            line-height: 44px;
            text-align: center;
            margin-bottom: 1rem;
            color: rgba(255, 255, 255, 1);
        }

        .card-services-item p {
            font-family: "Cairo";
            font-size: 16px;
            font-weight: 400;
            line-height: 17.6px;
            text-align: center;
            margin-bottom: 1rem;
            padding: 1rem 15rem;
            color: rgba(255, 255, 255, 1);
        }

        .card-services-item a {
            border-radius: 104px;
            background: #fff;
            font-family: "Cairo";
            font-size: 20px;
            font-weight: 600;
            line-height: 33.3px;
            text-align: center;
            margin-bottom: 1rem;
            color: rgba(11, 64, 103, 1);
            padding: .5rem 5rem;
        }

        .card-services-item a:hover {
            border: none;
            color: #fff;
            background: rgba(11, 64, 103, 1);
        }

        

        .card-service-info {
            border: none;
        }

        .card-service-info img {
            width: 50%;
            border: 1px solid rgba(246, 246, 246, 1);
            box-shadow: 4px 4px 20px 0px rgba(167, 167, 167, 0.25);
            margin-bottom: 1rem;
            border-radius: 24px;
        }

        .card-service-info .card-body {
            text-align: center;
        }
        .card-service-info h3{
            font-family: "Cairo";
            font-size: 24px;
            font-weight: 500;
            line-height: 36.12px;
            text-align: center;
            margin-bottom: 1rem;
            color:rgba(11, 64, 103, 1);
        }
        .card-service-info a{
            background: rgba(0, 134, 184, 1);
            font-family: "Cairo";
            font-size: 20px;
            font-weight: 600;
            line-height: 33.3px;
            text-align: center;
            color:rgba(255, 255, 255, 1);
            padding: .5rem 5rem;
            margin-bottom: 1rem;
            border-radius: 104px;
        }
    </style>

