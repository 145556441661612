<template>
  <div class="home">
     <SupportSection/>

         <section data-v-31c867e7="" class="our_projects" id="our_services">
            <div class="container">
                <div class="mt-5 mb-5">
                    <h2>Our Projects</h2>
                   
                    
                    
                                       <div class="text-center">
                                           <b-spinner label="Spinning"></b-spinner>
                                        </div>

                                        <div class="row" v-if="projectList.length > 0">
                                            <template  v-for="(project, key) in this.projectList" :key="key">
                                                    <div class="col-lg-4">
                                                        <div class="card card-project-item mb-5">
                                                            <div class="card-body">
                                                                <img :src="project.image" />
                                                                <h3>{{ project.name }}</h3>
                                                                <router-link :to="'/project-view/'+project.id">
                                                                    Get Started
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                            </template>
                                        </div>
                   


                </div>
            </div>
        </section>
        <UpperFooterComponent></UpperFooterComponent>
  </div>
</template>

<script>
import SupportSection from "@/components/rn/Support.component.vue"
import UpperFooterComponent from "@/components/layouts/UpprtFooter.component.vue";
import axios from 'axios'

export default {
  name: "Projects_list",
  components: {
    SupportSection,
    UpperFooterComponent
  },
  created() {
    this.$store.commit("setPage", "Projects_list");
    
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
  	data() {
		return {
			model: true,
			page: 1,
            support_id:1,
			perPage: 20,
			totalPages: 0,
			projectList: [],
		}
	},
	mounted() {
        //this.support_id = this.$route.params.id;
		this.getProjectsDBlist()
	},
	methods: {
		async getProjectsDBlist() {
			await axios.get(`https://api.robotera.com.sa/api/projects/${this.$route.params.id}&` + this.page).then(response => {
				console.log(response.data);
				this.projectList = response.data.data.data;
                this.page = response.data.current_page;
				this.totalPages = response.data.total;
                 console.log(this.projectList);
			}).catch(error => {
				this.projectList = []
			})
		},
	},
};
</script>
<style lang="css" scoped>
  
        .our_services {
            padding-top: 6rem;
        }

        .card-services-item {
            background: linear-gradient(90deg, #47AED1 8.5%, #DA9664 100%);
            border-radius: 20px;
            border: none;

        }

        .card-services-item .card-body {
            text-align: center;
        }

        .card-services-item h2 {
            font-family: "Cairo";
            font-size: 40px;
            font-weight: 700;
            line-height: 44px;
            text-align: center;
            margin-bottom: 1rem;
            color: rgba(255, 255, 255, 1);
        }

        .card-services-item p {
            font-family: "Cairo";
            font-size: 16px;
            font-weight: 400;
            line-height: 17.6px;
            text-align: center;
            margin-bottom: 1rem;
            padding: 1rem 15rem;
            color: rgba(255, 255, 255, 1);
        }

        .card-services-item a {
            border-radius: 104px;
            background: #fff;
            font-family: "Cairo";
            font-size: 20px;
            font-weight: 600;
            line-height: 33.3px;
            text-align: center;
            margin-bottom: 1rem;
            color: rgba(11, 64, 103, 1);
            padding: .5rem 5rem;
        }

        .card-services-item a:hover {
            border: none;
            color: rgba(11, 64, 103, 1);
            background: #fff;
        }

        .our_projects h2 {
            text-align: center;
            margin-bottom: 1rem;
        }

        .card-project-item {
            border: none;
            height: 100%;
            text-align: left;
        }

        .card-project-item img {
            width: 100%;
            height: 270px;
            object-fit: cover;
            border: 1px solid rgba(246, 246, 246, 1);
            box-shadow: 4px 4px 20px 0px rgba(167, 167, 167, 0.25);
            border-radius: 24px;
        }

        .card-project-item h3 {
            font-family: "Cairo";
            font-size: 20px;
            font-weight: 500;
            line-height: 30.1px;
            text-align: left;
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: rgba(129, 152, 170, 1);
        }

        .card-project-item a {
            border: 1px solid rgba(220, 220, 220, 1);
            background: rgba(255, 255, 255, 1);
            font-family: "Cairo";
            font-size: 20px;
            font-weight: 600;
            line-height: 29.1px;
            text-align: left;
            color: rgba(47, 47, 47, 1);
            border-radius: 32px;
            padding: .5rem 3rem;

        }
    </style>

