<template>
  <div class="home">
        <SupportSection/>
        <section data-v-31c867e7="" class="our_" id="our_services">
            <div class="container">
                <div class="mt-5 mb-5">
                    <h2>About RN Invent</h2>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum been the
                        industry's standard dummy
                    </p>
                    <div class="card card-service-info mt-5 mb-5">
                        <div class="card-body">
                            <img src="../assets//rn_images/78883d837ae760beec7c49d3b6e6161a.jpeg" />
                            <h3>Lorem Ipsum is simply dummy text of the printing</h3>
                            <router-link to="/projects/1">
                                Our Projects
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <UpperFooterComponent></UpperFooterComponent>
  </div>
</template>

<script>

import SupportSection from "@/components/rn/Support.component.vue"
import UpperFooterComponent from "@/components/layouts/UpprtFooter.component.vue";

export default {
  name: "SupportRNInvent",
  components: {
   SupportSection,
   UpperFooterComponent
  },
  created() {
    this.$store.commit("setPage", "SupportRNInvent");

    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },
};
</script>

<style lang="css" scoped>
       

        .card-service-info {
            border: none;
        }

        .card-service-info img {
            width: 50%;
            border: 1px solid rgba(246, 246, 246, 1);
            box-shadow: 4px 4px 20px 0px rgba(167, 167, 167, 0.25);
            margin-bottom: 1rem;
            border-radius: 24px;
        }

        .card-service-info .card-body {
            text-align: center;
        }
        .card-service-info h3{
            font-family: "Cairo";
            font-size: 24px;
            font-weight: 500;
            line-height: 36.12px;
            text-align: center;
            margin-bottom: 1rem;
            color:rgba(11, 64, 103, 1);
        }
        .card-service-info a{
            background: rgba(0, 134, 184, 1);
            font-family: "Cairo";
            font-size: 20px;
            font-weight: 600;
            line-height: 33.3px;
            text-align: center;
            color:rgba(255, 255, 255, 1);
            padding: .5rem 5rem;
            margin-bottom: 1rem;
            border-radius: 104px;
        }

        .card-service-info a:hover {
            border: none;
            color: #fff;
            background: rgba(11, 64, 103, 1);
        }

    </style>
